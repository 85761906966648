import React from 'react'
import ToSContainer from './terms-of-service.styles'
import { Typography } from '@material-ui/core'
import { getCurrencySymbol } from 'helpers/currency-helper'

const TermsOfServiceComponent = () => {
  return (
    <ToSContainer>
      <ToSContainer.header>
        <Typography variant='h1' gutterBottom>
          <ToSContainer.bold>
            SiteBuilder.com Terms of Service
          </ToSContainer.bold>
        </Typography>
        <Typography variant='body1' gutterBottom>
          Your use of SiteBuilder services is subject to the terms and
          conditions set forth in these SiteBuilder Terms of Service
          (“Agreement”). This Agreement explains (i) what’s allowed when using
          our Services; (ii) the rights you have as a user of our Services;
          (iii) the rights SiteBuilder has if you do something which is not
          allowed when using our Services; and (iv) many other important terms.
          This Agreement is a legal contract between you and SiteBuilder and it
          is important that you read it carefully. If there is anything you do
          not understand in this Agreement, please{' '}
          <ToSContainer.link href='mailto:legal@SiteBuilder.com'>
            contact us
          </ToSContainer.link>
          .
        </Typography>
        <ToSContainer.notice>
          NOTICE: this Agreement includes an alternative dispute resolution
          provision for disputes that may arise between users of our Services
          and SiteBuilder or its corporate affiliates. Please see Section 28(b){' '}
          <ToSContainer.link href='#ARBITRATION'>below</ToSContainer.link> which
          includes an{' '}
          <ToSContainer.link href='/terms/arbitration-agreement'>
            Arbitration Agreement
          </ToSContainer.link>{' '}
          and an agreement that all claims will be brought only in an individual
          capacity (and not as a class action or other representative
          proceeding). Please read it carefully. You may opt out of the
          Arbitration Agreement by following the opt out procedure described
          therein.
        </ToSContainer.notice>
      </ToSContainer.header>
      <ToSContainer.quickLinks>
        <Typography variant='h2' gutterBottom>
          <ToSContainer.bold>QUICK LINKS</ToSContainer.bold>
        </Typography>
        <Typography variant='link2' gutterBottom>
          <ToSContainer.link href='#ELIGIBILITY'>
            Eligibility, Registration and Account Security
          </ToSContainer.link>
        </Typography>
        <Typography variant='body1' gutterBottom>
          This section describes the eligibility criteria we require from all of
          our users. When you register to use our Services (as defined below),
          we need to make sure that you are able to legally contract with
          Sitebuilder. This section also explains that you are responsible for
          account security including all use of the Services through your User
          account, whether or not authorized by you.
        </Typography>
        <br />
        <Typography variant='link2' gutterBottom>
          <ToSContainer.link href='#HIPAA'>HIPAA Disclaimer</ToSContainer.link>
        </Typography>
        <Typography variant='body1' gutterBottom>
          Sitebuilder Services do not comply with the U.S. Health Insurance
          Portability and Accountability Act (“HIPAA”). This section describes
          our policy on HIPAA in more detail.
        </Typography>
        <br />
        <Typography variant='link2' gutterBottom>
          <ToSContainer.link href='#TERMINATION'>
            Term and Termination Policy
          </ToSContainer.link>
        </Typography>
        <Typography variant='body1' gutterBottom>
          Sitebuilder offers website builder plans for a fixed period of time
          that you select upon purchase (e.g. 1 month, 1 year, etc.). Even
          though we do not want you to, we know that one day you might want to
          leave Sitebuilder. The instructions to cancel or disable automatic
          renewal can be found here.
        </Typography>
        <br />
        <Typography variant='link2' gutterBottom>
          <ToSContainer.link href='#AUTORENEWAL'>
            Auto-Renewal Terms
          </ToSContainer.link>
        </Typography>
        <Typography variant='body1' gutterBottom>
          To ensure uninterrupted service, your Services will automatically
          renew on your renewal date. This section explains this process in more
          detail.
        </Typography>
        <br />
        <Typography variant='link2' gutterBottom>
          <ToSContainer.link href='#MONEYBACK'>
            Refunds and 14-Day Money-Back Guarantee
          </ToSContainer.link>
        </Typography>
        <Typography variant='body1' gutterBottom>
          This section describes Sitebuilder’s refund policy and 14-Day
          Money-Back Guarantee. If you purchase a plan with a fourteen (14) day
          money-back guarantee and cancel during the first fourteen (14) days of
          your term, you may receive a full refund of all basic website builder
          fees paid. If your plan included a free domain name and you cancel the
          Services, there is a non-refundable {getCurrencySymbol().domainPrice} domain fee. This fee not only
          covers our own costs, but you are welcome to keep the domain name and
          transfer it or point it to elsewhere.
        </Typography>
        <br />
        <Typography variant='link2' gutterBottom>
          <ToSContainer.link href='#RESPONSIBILITIES'>
            User’s Responsibilities
          </ToSContainer.link>
        </Typography>
        <Typography variant='body1' gutterBottom>
          All Users are required to comply with applicable law and have certain
          obligations with respect to their use of Sitebuilder Services. You are
          also required to cooperate with Sitebuilder and utilize hardware and
          software that is compatible with the Services. In addition, you are
          responsible for the security of your account and its content, as well
          as for maintaining a backup of your content and promptly removing any
          malware from your account.
        </Typography>
        <br />
        <Typography variant='link2' gutterBottom>
          <ToSContainer.link href='#BILLING'>
            Billing and Payment
          </ToSContainer.link>
        </Typography>
        <Typography variant='body1' gutterBottom>
          Sitebuilder offers a great range of Services to suit everyone’s needs
          and at prices to suit everyone’s pockets. The fees you pay are based
          on the plan you choose and any add-on products you purchase. All
          payments are taken, in advance, for the full term of your plan.
        </Typography>
        <br />
        <Typography variant='link2' gutterBottom>
          <ToSContainer.link href='#RESOURCE'>Resource Usage</ToSContainer.link>
        </Typography>
        <Typography variant='body1' gutterBottom>
          Customers are required to utilize server resources in an efficient and
          responsible manner. Excessive use of server CPU and memory resources
          by a customer can interfere with or prevent normal service performance
          for other customers. Additional information about our policy on CPU,
          Bandwidth and Disk Usage can be found here.
        </Typography>
        <br />
        <Typography variant='link2' gutterBottom>
          <ToSContainer.link href='#GOVERNING'>
            Governing Law and Arbitration
          </ToSContainer.link>
        </Typography>
        <Typography variant='body1' gutterBottom>
          The governing law and jurisdiction provision as set forth in Section
          28(a) shall apply to all Users. For Users who purchased or signed up
          for the Services after July 1, 2017, the arbitration clause in Section
          28(b) shall apply.
        </Typography>
        <br />
        <Typography variant='body1' gutterBottom>
          This Agreement is an agreement between Newfold Digital,
          Inc., operating as SiteBuilder (collectively, "us," "we,"
          "SiteBuilder," or the "Company") and you ("User" or "you" and "your").
          This Agreement sets forth the general terms and conditions of your use
          of the products and services made available by SiteBuilder and of the
          SiteBuilder website (collectively, the "Services"). By using the
          Services, you agree to be bound by this Agreement. Please read this
          Agreement carefully.
        </Typography>
        <br />
        <Typography variant='body1' gutterBottom>
          We may in our sole discretion change or modify this Agreement at any
          time. We will post a notice of any significant changes to this
          Agreement on the Sitebuilder website for at least thirty (30) days
          after the changes are posted and will indicate at the bottom of this
          Agreement the date these terms were last revised. Any changes or
          modifications to this Agreement shall be effective and binding on you
          as of the date indicated in a notice posted on this page, together
          with any options you may have as a current customer to accept or
          reject changes, where required by law or otherwise made available. If
          no date is specified, your use of the Services after such changes or
          modifications shall constitute your acceptance of the Agreement as
          modified. If you do not agree to abide by this Agreement, you are not
          authorized to use or access the Services and your sole remedy is to
          cancel your account.
        </Typography>
      </ToSContainer.quickLinks>
      <ToSContainer.content>
        <Typography variant='h1' gutterBottom>
          SiteBuilder.com Terms and Conditions
        </Typography>
        <ul>
          <br />
          <li>
            <Typography variant='h3'>
              <ToSContainer.bold>Policies</ToSContainer.bold>
            </Typography>
          </li>
        </ul>
        <Typography variant='body1' gutterBottom>
          Use of the Services is also governed by the following policies and
          agreements, which are incorporated by reference. By using the
          Services, you are also agreeing to the terms of the following policies
          and agreements.
        </Typography>
        <ul>
          <ul>
            <li>
              <ToSContainer.link href='/terms/domain-registration-agreement'>
                Domain Registration Agreement
              </ToSContainer.link>
            </li>
            <br />
            <li>
              <ToSContainer.link href='/terms/copyright-infringement-policy'>
                Copyright Policy
              </ToSContainer.link>
            </li>
            <br />
            <li>
              <ToSContainer.link href='/terms/data-request-policy'>
                Data Request Policy
              </ToSContainer.link>
            </li>
            <br />
            <li>
              <ToSContainer.link href='/terms/acceptable-use#ZEROTOLERANCESP'>
                Anti-Spam Policy
              </ToSContainer.link>
            </li>
            <br />
            <li>
              <ToSContainer.link
                href='https://newfold.com/privacy-center'
                rel='noopener'
                target='_blank'
              >
                Privacy Notice
              </ToSContainer.link>
            </li>
            <br />
            <li>
              <ToSContainer.link href='/terms/acceptable-use'>
                Acceptable Use Policy
              </ToSContainer.link>
            </li>
            <br />
            <li>
              <ToSContainer.link href='/terms/arbitration-agreement'>
                Arbitration Agreement
              </ToSContainer.link>
            </li>
          </ul>
        </ul>
        <Typography variant='body1' gutterBottom>
          Additional terms may apply to certain Services, and such additional
          terms will be made available to you and will be incorporated by
          reference with such Services.
        </Typography>
        <ul>
          <li>
            <Typography variant='h3'>
              <ToSContainer.anchor id='ELIGIBILITY' />
              <ToSContainer.bold>
                Eligibility; Registration and Account Security
              </ToSContainer.bold>
            </Typography>
          </li>
          <br />
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                The Services are intended solely for users who are eighteen (18)
                years of age or older. Any registration by, use of or access to
                the Services by anyone under eighteen (18) is unauthorized and
                in violation of this Agreement. By registering for or using the
                Services, you represent and warrant that you are eighteen (18)
                years of age or older.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                If you use the Services on behalf of another party, company or
                other organization, you represent and warrant that you are
                authorized to bind such party, company or organization to this
                Agreement and to act on behalf of such party, company or
                organization with respect to any actions you take in connection
                with the Services.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                You agree to (i) provide accurate, current and complete
                information about you and your organization (if applicable) as
                prompted by the registration forms ("Registration Data"); (ii)
                maintain the confidentiality of your password and other
                information related to the security of your account; (iii)
                maintain and promptly update the Registration Data and any other
                information you provide to SiteBuilder, to keep such information
                accurate, current and complete; and (iv) be fully responsible
                for all use of your account and for any actions that take place
                through your account, whether or not authorized by you.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                You acknowledge and accept that despite the security measures
                SiteBuilder takes in connection with the Services, SiteBuilder’s
                system and/or User Websites (as defined below) may nonetheless
                become compromised, including without limitation, by hackers,
                Internet viruses, malware, worms or Trojan horses, or the like.
                Under such circumstances, SiteBuilder may take corrective action
                as it deems appropriate in its sole discretion and you
                acknowledge and agree that SiteBuilder shall have no liability
                to you for any damage or loss that you may incur due to such
                corrective action.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                SiteBuilder does not recommend the use of the Services for
                hosting or storing personal or sensitive content and shall bear
                no responsibility or liability in the event of compromise, loss
                of, or damage to, any such content.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>
                  You are responsible for the security of your User account,
                  User Content and User Websites. You further acknowledge and
                  agree that you are solely responsible for backing-up your User
                  account, including without limitation, all User Content and
                  User Websites.
                </ToSContainer.bold>
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3'>
              <ToSContainer.bold>
                Prohibited Persons (Countries, Regions, Entities, and
                Individuals).
              </ToSContainer.bold>
            </Typography>
          </li>
        </ul>

        <Typography variant='body1'>
          The Services are subject to export control and economic sanctions laws
          and regulations administered or enforced by the United States
          Department of Commerce, Department of Treasury’s Office of Foreign
          Assets Control (“OFAC”), Department of State, and other United States
          authorities (collectively, “U.S. Trade Laws”). You may not use the
          Services to export or re-export, or permit the export or re-export, of
          software or technical data in violation of U.S. Trade Laws. In
          addition, by using the Services, you represent and warrant that you
          are not (a) an individual, organization or entity organized 
          or located in a country or territory that is the target of OFAC sanctions 
          (including Cuba, Iran, Syria, North Korea, or the Crimea, 
          the Donetsk People’s Republic, or the Luhansk People’s Republic regions of Ukraine);
          (b) designated as a Specially Designated National or
          Blocked Person by OFAC or otherwise owned, controlled, or acting on
          behalf of such a person; (c) otherwise a prohibited party under U.S.
          Trade Laws; or (d) engaged in nuclear, missile, chemical or biological
          weapons activities to which U.S. persons may not contribute without a
          U.S. Government license. Unless otherwise provided with explicit
          written permission, SiteBuilder also does not register, and prohibits
          the use of any of our Services in connection with, any Country-Code
          Top Level Domain Name (“ccTLD”) for any country or territory that is
          the target of OFAC sanctions. The obligations under this section shall
          survive any termination or expiration of this Agreement or your use of
          the Services.
        </Typography>
        <br />
        <ul>
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>SiteBuilder Content.</ToSContainer.bold>
            </Typography>
          </li>
          <br />
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                Except for User Content (as defined below), all content made
                available through the Services, including images made available
                through any website builder tools provided by SiteBuilder (the
                “Licensed Images”), designs, templates, text, graphics, images,
                video, information, software, audio and other files, and their
                selection and arrangement, and all software used to provide the
                Services (collectively with the Licensed Images, "SiteBuilder
                Content"), are the property of SiteBuilder or its licensors. No
                SiteBuilder Content may be modified, copied, distributed,
                framed, reproduced, republished, downloaded, scraped, displayed,
                posted, transmitted, sold or exploited for any purpose in any
                form or by any means, in whole or in part, other than as
                expressly permitted in this Agreement. You may not, directly or
                indirectly, reverse engineer, decompile, disassemble or
                otherwise attempt to derive source code or other trade secrets
                from any SiteBuilder Content.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                To the extent applicable, you are granted a limited, revocable,
                non-sublicensable, license to use the Licensed Images solely in
                connection with the SiteBuilder Services. You are prohibited
                from using any Licensed Images: (i) with pornographic,
                defamatory, or unlawful content or in such a manner that
                infringes upon any third party’s trademark or intellectual
                property rights; (ii) as a trademark, service mark, or logo; and
                (iii) portraying any person depicted therein (a "Model") in a
                way that a reasonable person would find offensive, including but
                not limited to depicting a Model: (a) in connection with
                pornography, "adult videos", adult entertainment venues, escort
                services, dating services, or the like; (b) in connection with
                the advertisement or promotion of tobacco products; (c) as
                endorsing a political party, candidate, elected official, or
                opinion; (d) as suffering from, or medicating for, a physical or
                mental ailment; or (e) engaging in immoral or criminal
                activities.{' '}
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                Any use of the SiteBuilder Content other than as specifically
                authorized herein is prohibited and will automatically terminate
                your rights with respect to your use of the Services and the
                SiteBuilder Content granted herein. All rights of SiteBuilder or
                its licensors that are not expressly granted in this Agreement
                are reserved to SiteBuilder and its licensors.
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>User Content.</ToSContainer.bold>
            </Typography>
          </li>
          <br />
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                You may be able to upload, store, publish, display and
                distribute information, text, photos, videos, emails, and other
                content on or through the Services (collectively, “User
                Content”). User Content includes any content posted by you and
                users of any of your websites hosted through the Services (“User
                Websites”). You are solely responsible for any and all User
                Content and any transactions or other activities conducted on or
                through User Websites. By posting or distributing User Content
                on or through the Services, you represent and warrant to
                SiteBuilder that (i) you have all necessary rights to post or
                distribute such User Content, and (ii) your posting or
                distribution of such User Content does not infringe or violate
                the rights of any third party.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                You acknowledge and agree that SiteBuilder may, but is not
                obligated to, monitor User Content and may immediately take any
                corrective action in SiteBuilder's sole discretion, including
                without limitation removal of all or a portion of the User
                Content, and suspension or termination of any and all Services
                without refund of any pre-paid fees. You hereby agree that
                SiteBuilder shall have no liability due to any corrective action
                that SiteBuilder may take, including without limitation
                suspension or termination of Services.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                You hereby grant to SiteBuilder, to the extent necessary to
                provide the Services, a non-exclusive, royalty-free, worldwide
                right and license to: (i) use, reproduce, publicly perform,
                publicly display, modify, translate, excerpt (in whole or in
                part), publish and distribute User Content and the User Website;
                and (ii) make archival or back-up copies of the User Content and
                the User Website. Except for the rights expressly granted above,
                SiteBuilder is not acquiring any right, title or interest in or
                to the User Content, all of which shall remain solely with you.
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.anchor id='HIPAA' />
              <ToSContainer.bold>HIPAA Disclaimer.</ToSContainer.bold>
            </Typography>
          </li>
        </ul>

        <Typography variant='body1'>
          The Services do not comply with the U.S. Health Insurance Portability
          and Accountability Act (“HIPAA”). You are solely responsible for
          compliance with all applicable laws governing the privacy and security
          of personal data, including medical or other sensitive data. You
          acknowledge that the Services are not appropriate for the storage or
          control of access to sensitive data, such as information about
          children or medical or health information. SiteBuilder does not
          control or monitor the information or data you store on, or transmit
          through, our Services. We specifically disclaim any representation or
          warranty that the Services, as offered, comply with HIPAA. Users
          requiring secure storage of “Protected Health Information” as defined
          under HIPAA are expressly prohibited from using this Service for such
          purposes. Storing and permitting access to “Protected Health
          Information,” is a material violation of this Agreement, and grounds
          for immediate account termination. SiteBuilder does not sign “Business
          Associate Agreements,” and you agree that SiteBuilder is not a
          Business Associate or subcontractor or agent of yours pursuant to
          HIPAA. If you have questions about the security of your data, please
          contact us by phone or chat.
        </Typography>
        <br />
        <ul>
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>
                Payment Card Industry Security Standard Disclaimer.
              </ToSContainer.bold>
            </Typography>
          </li>
        </ul>

        <Typography variant='body1'>
          SiteBuilder complies with the Payment Card Industry Security Standard
          (“PCI Standard”) in connection with the collection and processing of
          your data and billing information. However, you are solely responsible
          for the security of the data and billing information you collect on
          your User Website. SiteBuilder does not monitor User Websites for
          compliance and therefore we are not able to verify whether any User
          Website complies with the PCI Standard.
        </Typography>
        <br />
        <ul>
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>
                Compliance with Applicable Law.
              </ToSContainer.bold>
            </Typography>
          </li>
        </ul>

        <Typography variant='body1'>
          You agree to comply with all applicable laws, rules, and regulations,
          including without limitation all local rules where you reside or your
          organization is located regarding User Content, User Websites, online
          activities, email and your use of the Services. More specifically, but
          without limitation, you agree to comply with all applicable laws
          regarding the transmission of technical data exported to or from the
          United States or the country in which you reside. The Services are
          controlled and operated by us from our offices within the United
          States (although we may share data with third parties around the world
          to assist us in providing the Services as further described in our
          Privacy Notice) and we make no representation that the Services are
          appropriate or available for use in other locations. Those who access
          the Services from other locations do so at their own initiative and
          risk, and are fully responsible for compliance with all applicable
          laws in those locations. We do not offer the Services where prohibited
          by law.
        </Typography>
        <br />
        <Typography variant='h3' gutterBottom>
          For the purposes of European Directive 95/46/EC, the General Data
          Protection Regulation 2016/679) (“GDPR”) and any applicable national
          implementing laws in your jurisdiction, and with respect to your
          subscribers’ or customers’ personal data, you acknowledge and agree
          that you are the Controller (as that term is defined in the GDPR), and
          we are a Processor (as that term is defined in the GDPR) insofar as
          you may store personal data through your use of our Services only as
          permitted and subject to the terms of this Agreement. You also
          acknowledge and agree that you are responsible for complying with all
          obligations of a data controller under applicable law (including the
          GDPR).
        </Typography>
        <br />
        <Typography variant='h3' gutterBottom>
          To the extent the GDPR applies to you, you represent and warrant that
          in using our Services, you will clearly describe in writing how you
          plan to use any personal data collected and you will ensure you have a
          legitimate legal basis to transfer such personal data to us and that
          you have the necessary permission to allow us to receive and process
          (e.g., store) such personal data on your behalf. The additional data
          processing terms set forth{' '}
          <ToSContainer.link href='https://www.endurance.com/privacy/data-processing-addendum?'>
            here
          </ToSContainer.link>{' '}
          shall apply where you are a Controller subject to the GDPR.
        </Typography>
        <br />
        <ul>
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.anchor id='RESPONSIBILITIES' />
              <ToSContainer.bold>
                Additional User Responsibilities.
              </ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                You will be solely responsible for all activities conducted on
                or through a User Website, whether or not authorized by you and
                any transactions or interactions with end users of your User
                Website. You will be solely responsible for providing end users
                of your User Website with any applicable terms of use and
                privacy notice, including any required disclosure or explanation
                of the features of your User Website and any goods or services
                offered through your User Website.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                You will cooperate fully with SiteBuilder in connection with
                SiteBuilder's provision of the Services. It is solely your
                responsibility to provide any equipment or software that may be
                necessary for you to use the Services. Delays in the performance
                of your obligations under this Agreement will extend the time
                for SiteBuilder's performance of its obligations that depend on
                your performance.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                You will be solely responsible for ensuring that all User
                Content and User Websites are compatible with the hardware and
                software used by SiteBuilder to provide the Services, which
                hardware and software may be changed by SiteBuilder from time to
                time in its sole discretion.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>
                  You will be solely responsible for backing-up all User Content
                  off of SiteBuilder's servers. This is an affirmative duty.
                  SiteBuilder is not responsible for the loss of any User
                  Content. Note: It is essential that Users backup files
                  offline, even if user purchases or has backup products
                  provided by SiteBuilder.
                </ToSContainer.bold>
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                You are responsible for the security of your User account, User
                Content, and User Websites. You will maintain at all times, and
                are responsible for, the security and confidentiality of all
                account credentials, including your username and password.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                You will use your best efforts to ensure that the User Content
                and User Websites are and will at all times remain free of all
                computer viruses, worms, Trojan horses and other malicious code.
                You are required to prevent, identify, and promptly remove or
                disable from your User account, User Content and User Websites
                any code that may disrupt, disable, harm or cause the misuse of
                your account, SiteBuilder Content or the Services in any way
                (including any malware).
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Third Party Websites.</ToSContainer.bold>
            </Typography>
          </li>
        </ul>

        <Typography variant='body1' gutterBottom>
          The Services may contain links to other websites that are not owned or
          controlled by SiteBuilder ("Third Party Sites"), as well as articles,
          photographs, text, graphics, pictures, designs, sound, video,
          information, and other content or items belonging to or originating
          from third parties ("Third Party Content"). Such Third Party Sites and
          Third Party Content are not investigated, monitored or checked for
          accuracy, appropriateness, or completeness by us, and we are not
          responsible for any Third Party Sites accessed through the Services or
          any Third Party Content posted on or made available through the
          Services, including the content, accuracy, offensiveness, opinions,
          reliability, security, privacy practices or other policies of the
          Third Party Sites or the Third Party Content. If you decide to access
          the Third Party Sites or to access or use any Third Party Content, you
          do so at your own risk and our terms and policies do not apply. You
          should review the applicable terms and policies, including privacy and
          data gathering practices, of any site to which you navigate.
        </Typography>
        <br />
        <ul>
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.anchor id='BILLING' />
              <ToSContainer.bold>Billing and Payment.</ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Fees Due.</ToSContainer.bold> You will pay to
                SiteBuilder all fees for the Services set forth in the
                registration form presented to you at the time you order the
                Services and for all fees for subsequent Renewal Periods at the
                time of renewal.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Price Increases.</ToSContainer.bold>{' '}
                SiteBuilder may increase the fees for the Services (i) in the
                manner permitted in the applicable description of the particular
                Services published by SiteBuilder on the SiteBuilder website or
                in a promotional offer (the “Service Description”) and (ii) at
                any time on or after expiration of the Initial Term by providing
                thirty (30) days prior written notice to you. Written notice may
                be in the form of (i) notices and updates provided through the
                User billing tool provided as part of the Services, (ii) notices
                and updates otherwise provided through the Services, or (iii)
                notifications of pricing for renewal terms. It is your sole
                responsibility to periodically review billing-related
                information provided by SiteBuilder through the User billing
                tool or other methods of communications and notices sent or
                posted by SiteBuilder.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Taxes.</ToSContainer.bold> The advertised
                fees do not include any applicable sales, use, revenue, excise
                or other taxes imposed by any taxing authority with respect to
                the Services provided hereunder. All such taxes may be added to
                SiteBuilder's invoices for the fees as separate charges to be
                paid by you. All fees are non-refundable when paid except as
                otherwise provided herein.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.anchor id='AUTORENEWAL' />
                <ToSContainer.bold>Automatic Renewals.</ToSContainer.bold> By
                purchasing the Services, you agree to allow SiteBuilder to place
                your account on a recurring payment plan. The account will
                automatically be re-billed according to the term length of the
                Services you select. For Services with term lengths of three (3)
                months or longer, SiteBuilder shall provide notice of the
                upcoming charge to User no later than thirty (30) days prior to
                the payment date for each Renewal Period. This notification will
                be sent to the contact email address on file for the Account.
                You may disable automatic renewal at any time{' '}
                <ToSContainer.link href='https://my.sitebuilder.com/account/settings/myservices'>
                  online
                </ToSContainer.link>{' '}
                by logging into your control panel, or by contacting SiteBuilder
                by phone or chat.{' '}
                <ToSContainer.bold>
                  Unless you disable the automatic renewal option, we will
                  automatically renew the Services up to fifteen (15) days
                  before your renewal date and will take payment from the
                  payment method we have on file.
                </ToSContainer.bold>{' '}
                SiteBuilder may make multiple attempts to collect payment from
                the payment method on file if the initial attempt is not
                successful.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Add-On Services.</ToSContainer.bold> If you
                purchase certain add-on services from SiteBuilder such as Domain
                Privacy, SSL certificates, or SiteLock, you may be required to
                apply the Service to a specific domain name to begin using the
                Service. SiteBuilder is not responsible if you fail to apply an
                add-on to a domain name and will not provide refunds for any
                purchased but unused Services.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Failure to Pay.</ToSContainer.bold> If you
                fail to pay the fees due, we may continue to attempt to collect
                payment from the payment method on file, suspend, and/or
                terminate your Services and pursue the collection costs incurred
                by SiteBuilder, including without limitation, any arbitration
                and legal fees and SiteBuilder's reasonable attorneys' fees. If
                any check is returned for insufficient funds, SiteBuilder may
                impose a minimum processing charge of $25.00 plus any applicable
                taxes. Accounts will not be activated or reactivated until all
                outstanding amounts are paid.{' '}
                <ToSContainer.bold>
                  We are not responsible for any deleted or lost User Content
                  that results from any suspension or reactivation.
                </ToSContainer.bold>
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Fraud.</ToSContainer.bold> It is a violation
                of this Agreement to misuse or fraudulently use credit cards,
                charge cards, electronic funds transfers, and/or electronic
                checks. We may report all such misuses and fraudulent uses (as
                determined by us in our sole discretion) to appropriate
                government and law enforcement authorities, credit reporting
                services, financial institutions and credit card companies.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Disputes.</ToSContainer.bold> You have ninety
                (90) days to dispute any charge or payment processed by
                SiteBuilder. If you have a question concerning a charge you
                believe is incorrect, please contact us at
                billing@SiteBuilder.com and we will investigate. If you initiate
                a chargeback, there may be a minimum charge of $15.00 plus
                applicable taxes to reactivate your account and we reserve the
                right to suspend your account for the duration of the dispute.
                Accounts that have an open dispute may be disabled for security
                purposes
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Email Marketing.</ToSContainer.bold>
            </Typography>
          </li>
        </ul>
        <Typography variant='body1' gutterBottom>
          If you elect to use the email marketing feature ("Email Marketing"),
          your use of Email Marketing shall be governed by the Constant Contact
          Website and Products Terms and Conditions of Use (the "Email Marketing
          Terms"). To the extent there is any conflict between the Email
          Marketing Terms and this Agreement, the Email Marketing Terms shall
          control solely with respect to your use of Email Marketing.
        </Typography>

        <ul>
          <br />
          <li>
            <Typography variant='h3'>
              <ToSContainer.bold>Store.</ToSContainer.bold>
            </Typography>
          </li>
          <br />
          <Typography variant='body1'>
            If you elect to use Sitebuilder’s e-commerce store Services
            ("Store") for selling any of your products and/or services ("Store
            Content"), you are solely responsible for any and all Store Content
            and any transactions or other activities conducted on or through the
            Store. Your Store activities are your responsibility and Sitebuilder
            disclaims any and all liability related to any Store Content. You
            represent and warrant to Sitebuilder that (i) you have all necessary
            rights to post or distribute such Store Content, and (ii) your Store
            Content does not infringe or violate the rights of any third party.
          </Typography>
          <br />
          <ul>
            <li>
              <Typography variant='body1'>
                You acknowledge and agree that Sitebuilder may, but is not
                obligated to, monitor your Store and may take any corrective
                action in Sitebuilder's sole discretion, including without
                limitation removal of all or a portion of the Store Content, and
                suspension or termination of any and all Services without refund
                of any pre-paid fees. You hereby agree that Sitebuilder shall
                have no liability due to any corrective action that Sitebuilder
                may take, including without limitation suspension or termination
                of your Store.
              </Typography>
            </li>
            <br />
            <li>
              <Typography variant='body1'>
                You acknowledge and agree that you are solely responsible for
                your compliance with the following:
              </Typography>
            </li>
            <ul>
              <br />
              <li>
                <Typography variant='body1'>
                  all applicable laws and regulations related to the Store and
                  any Store Content including any related consumer, data
                  privacy, and e-commerce laws;
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant='body1'>
                  taxes and fees associated with the Store, including taxes
                  related to purchase or sale of products and services in
                  connection with the Store;
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant='body1'>
                  customer service for the Store, including any inquiries,
                  concerns, warranties you may offer, or claims and complaints
                  relating to the Store;
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant='body1'>
                  fulfilment and the delivery of Store Content to your
                  customers; and
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant='body1'>
                  visibility for all terms and policies that may apply,
                  including but not limited to privacy policies, cookie
                  policies, return policies, and any offered warranties.
                </Typography>
              </li>
              <br />
              <li>
                <Typography variant='body1'>
                  Your Store Content is subject to Sitebuilder's Acceptable Use
                  Policy. In the event you violate this policy, Sitebuilder
                  reserves the right to, at any time and in our sole discretion,
                  without notice, suspend and disable access, or remove your
                  Store and/or Store Content without any liability to you or
                  your customers, including without limitation, any loss of
                  profits, revenue, data, goodwill, or other losses except where
                  prohibited by law.
                </Typography>
              </li>
              <br />
            </ul>
          </ul>
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>LogoBuilder.</ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <br />
            <li>
              <Typography variant='body1' gutterBottom>
                If you elect to use Sitebuilder's LogoBuilder Service
                ("LogoBuilder") to create a logo or design for your use, you
                acknowledge that LogoBuilder uses certain elements, including
                colors, fonts, icons, and other designs. These elements are also
                made available to other Users and, as such, any logo created by
                LogoBuilder may be similar or identical to logos created by other
                Users who use LogoBuilder. LOGOS CREATED BY LOGOBUILDER ARE PROVIDED
                ON AN "AS-IS" AND "AS-AVAILABLE" BASIS AND SITEBUILDER MAKES
                NO WARRANTIES THAT THE LOGOS DO NOT INFRINGE THE INTELLECTUAL
                PROPERTY RIGHTS OF ANY THIRD PARTY. It is your responsibility to
                determine (a) whether any logo created by LogoBuilder is subject
                to any third party rights and (b) whether you may use and/or
                register your logo as a trademark.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                You may not assign or resell your LogoBuilder logo to any third
                party, and you may not challenge the use or registration of any
                other logo created by LogoBuilder on behalf of another User.
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Appointment Booking.</ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <br />
            <li>
              <Typography variant='body1' gutterBottom>
                If you elect to use Sitebuilder's Appointment Booking Service (“Booking”) to schedule appointments through the User Website,
                you acknowledge that you are responsible for managing and fulfilling your appointments and service offerings.
                BOOKING IS PROVIDED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS AND SITEBUILDER MAKES NO WARRANTIES THAT THE SERVICE WILL PERFORM IN AN ERROR-FREE AND UNINTERRUPTED MANNER.
                SITEBUILDER HEREBY EXPRESSLY DISCLAIMS ANY LIABILITY FOR LOSS OF PROFITS OR BUSINESS RESULTING FROM YOUR USE OF BOOKING.
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.anchor id='TERMINATION' />
              <ToSContainer.bold>
                Term and Termination of the Services.
              </ToSContainer.bold>
            </Typography>
          </li>
          <br />
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Term of Services.</ToSContainer.bold> The
                initial term of the Services purchased by you will be for the
                time period set forth in the registration form presented to you
                when you order the Services (the "Initial Term"). Unless you
                cancel prior to the end of the Initial Term or as otherwise
                stated in a notice sent to you at least thirty (30) days prior
                to the expiration of your then-current term, the Services will
                automatically renew for successive periods of equal length as
                the Initial Term (each a “Renewal Period”). You acknowledge,
                agree, and authorize us to automatically bill the applicable fee
                and/or charge your credit card or other payment method on file
                up to fifteen (15) days prior to the end of each Renewal Period,
                unless you terminate or cancel the Services prior to such charge
                as provided in this section. The “Term” of this Agreement shall
                include the Initial Term and all Renewal Periods, if any.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Termination Procedure.</ToSContainer.bold>{' '}
                You may terminate or cancel the Services you purchased at any
                time during the Term by giving SiteBuilder notice by phone or
                chat or online form where provided. The cancellation request is
                subject to verification of ownership of the account and/or
                domain, as determined in SiteBuilder's sole discretion. In the
                event of such cancellation, you shall be obligated to pay all
                fees and charges accrued prior to the effectiveness of such
                cancellation subject to the terms of the fourteen (14) day
                money-back guarantee if applicable. After the account is
                canceled, all User Content will be permanently removed from the
                server. Please make a backup of all User Content before you
                contact SiteBuilder to cancel your account.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>
                  Termination by SiteBuilder.
                </ToSContainer.bold>{' '}
                SiteBuilder may terminate your access to the Services, in whole
                or in part, including deletion or confiscation of all files, content, and/or domain name registrations, 
                without notice in the event that: (i) you fail to
                pay any fees due hereunder to SiteBuilder; (ii) you violate the
                terms and conditions of this Agreement; (iii) your conduct may
                harm SiteBuilder or others, cause SiteBuilder or others to incur
                liability, or disrupt SiteBuilder's business operations (as
                determined by SiteBuilder in its sole discretion); (iv) you are
                abusive toward SiteBuilder's staff in any manner; or (v) for any
                other lawful reason, including to comply with applicable law, 
                or as otherwise specified in this Agreement.
                In such event, SiteBuilder will not refund to you any fees paid
                in advance of such termination, and you shall be obligated to
                pay all fees and charges accrued prior to the effectiveness of
                such termination.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Modification of Services.</ToSContainer.bold>{' '}
                SiteBuilder reserves the right to modify, change, or discontinue
                any aspect of the Services at any time.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Data Deletion.</ToSContainer.bold> Upon
                termination of the Services for any reason, User Content, User
                Websites, and other data will be deleted. You are always
                responsible for maintaining back-up copies of all User Content
                and other data. SiteBuilder is not responsible for the loss of
                any User Content. Note: It is essential that Users backup files
                offline, even if user purchases or has products provided by
                SiteBuilder.
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Refunds.</ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.anchor id='MONEYBACK' />
                <ToSContainer.bold>
                  14 Day Money-Back Guarantee
                </ToSContainer.bold>
              </Typography>
            </li>
            <ul>
              <li>
                <Typography variant='body1' gutterBottom>
                  If you purchase an account with a fourteen (14) day money-back
                  guarantee, you may receive a full refund of all basic website
                  builder fees paid (the “Money-back Guarantee Refund”) if you
                  cancel within the first fourteen (14) days of the Initial Term
                  (the “Money-back Guarantee Period”). To request a Money-back
                  Guarantee Refund, please contact our billing department at
                  billing@SiteBuilder.com. The Money-back Guarantee Refund shall
                  only accrue and be due to you upon your compliance with, and
                  subject in all respects to, the terms and conditions of this
                  section.Money-back Guarantee Refunds only apply to basic
                  website builder services and do not apply to domain
                  registration fees, setup fees, or any fees for additional
                  Services.
                </Typography>
              </li>
              <li>
                <Typography variant='body1' gutterBottom>
                  The Money-back Guarantee Refund is valid for PayPal and credit
                  card payments only. Due to the costs associated with
                  processing payments made by other methods, we are not able to
                  offer the Money-back Guarantee Refund for other payment
                  methods. Any refunds are made at the sole discretion of
                  SiteBuilder.
                </Typography>
              </li>
              <li>
                <Typography variant='body1' gutterBottom>
                  Only first-time accounts are eligible for the Money-back
                  Guarantee Refund. For example, if you previously had an
                  account with SiteBuilder and you canceled your account and
                  signed up for a new account, you will not be eligible for the
                  Money-back Guarantee Refund on the second account. In
                  addition, refunds are not offered for accounts that are
                  suspended or terminated for violating this Agreement.
                </Typography>
              </li>
            </ul>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Domain Name Fee.</ToSContainer.bold>
              </Typography>
            </li>
            <Typography variant='body1' gutterBottom>
              If your plan includes a free domain name and you are entitled to a
              refund, our standard fee of {getCurrencySymbol().domainPrice} for the domain name (and any
              applicable taxes) (the “Domain Name Fee”) will be deducted from
              your refund and you will get to retain your domain name. For the
              avoidance of doubt, the Domain Name Fee will be deducted for all
              refunds, including without limitation, Money-back Guarantee
              Refunds. .
            </Typography>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>
                SiteBuilder as Reseller or Licensor.
              </ToSContainer.bold>
            </Typography>
          </li>
        </ul>
        <Typography variant='body1' gutterBottom>
          SiteBuilder is acting only as a reseller or licensor of certain third
          party services, hardware, software and equipment used in connection
          with the Services ("Non-SiteBuilder Products"). SiteBuilder shall not
          be responsible for any changes in the Services that cause any
          Non-SiteBuilder Product to become obsolete, require modification or
          alteration, or otherwise affect the performance of the Services. Any
          malfunction or manufacturer's defects of Non-SiteBuilder Products
          either sold, licensed or provided by SiteBuilder to you or purchased
          directly by you used in connection with the Services will not be
          deemed a breach of SiteBuilder's obligations under this Agreement. Any
          rights or remedies you may have regarding the ownership, licensing,
          performance or compliance of any Non-SiteBuilder Product are limited
          to those rights extended to you by the manufacturer of such
          Non-SiteBuilder Product. You are entitled to use any Non-SiteBuilder
          Product supplied by SiteBuilder only in connection with your permitted
          use of the Services. You shall use your best efforts to protect and
          keep confidential all intellectual property provided by SiteBuilder to
          you through any Non-SiteBuilder Product and shall make no attempt to
          copy, alter, reverse engineer, or tamper with such intellectual
          property or to use it other than in connection with the Services. You
          shall not resell, transfer, export or re-export any Non-SiteBuilder
          Product, or any technical data derived therefrom, in violation of any
          applicable United States or foreign law.
        </Typography>
        <ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>
                Internet Protocol (IP) Address Ownership.
              </ToSContainer.bold>
            </Typography>
          </li>
        </ul>
        <Typography variant='body1' gutterBottom>
          If SiteBuilder assigns you an Internet Protocol ("IP") address for
          your use, you shall have no right to use that IP address except as
          permitted by SiteBuilder in its sole discretion in connection with the
          Services during the Term. SiteBuilder shall retain ownership of all IP
          addresses assigned to you by SiteBuilder, and SiteBuilder reserves the
          right to change or remove any and all such IP addresses in its sole
          discretion.
        </Typography>
        <ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.anchor id='RESOURCE' />
              <ToSContainer.bold>Resource Usage.</ToSContainer.bold>
            </Typography>
          </li>
        </ul>
        <Typography variant='body1' gutterBottom>
          Website builder space is intended for use in accordance with,
          SiteBuilder's{' '}
          <ToSContainer.link href='terms/acceptable-use'>
            Acceptable Use Policy
          </ToSContainer.link>
          , and is limited to Web files, active e-mail and content of the hosted
          User Websites, not for storage (whether of media, e-mails, or other
          data). Website builder space further may not be used as offsite
          storage of electronic files, or e-mail. SiteBuilder expressly reserves
          the right to review every user account for excessive usage of CPU,
          bandwidth, disk space and other resources that may be a result of your
          violation of this Agreement or the Acceptable Use Policy. SiteBuilder
          may, in its sole discretion, terminate access to the Services, apply
          additional fees, or remove/delete User Content for those User accounts
          that are found to be in violation of SiteBuilder policies. You hereby
          agree that SiteBuilder shall have no liability due to any action that
          SiteBuilder may take, including without limitation suspension or
          termination of Services in connection with your violation of this
          section.
        </Typography>
        <ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Marketing Credits.</ToSContainer.bold>
            </Typography>
          </li>
        </ul>
        <Typography variant='body1'>
          Some SiteBuilder plans include free marketing credits offered by third
          party vendors which can be redeemed by customers located in the United
          States only. Additional terms and conditions apply:
        </Typography>
        <ul>
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                Full terms and conditions for Google Ads can be found here{' '}
                <ToSContainer.link href='http://www.google.com/adwords/coupons/terms.html'>
                  http://www.google.com/adwords/coupons/terms.html
                </ToSContainer.link>
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                Additional terms and conditions for Bing Ad Credits can be found
                here{' '}
                <ToSContainer.link href='http://www.sitebuilder.com/bing'>
                  http://www.sitebuilder.com/bing
                </ToSContainer.link>
              </Typography>
            </li>
          </ul>
        </ul>
        <ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Parked Domain Services.</ToSContainer.bold>
            </Typography>
          </li>
        </ul>
        <Typography variant='body1' gutterBottom>
          By registering for the Services you agree that SiteBuilder may point
          your domain name or DNS to one of SiteBuilder's or SiteBuilder's
          affiliates web pages as a default landing page, and that they may
          place advertising on your web page (the “Parked Pages”). You shall
          have no right to any compensation and shall not be entitled to receive
          any funds related to the monetization of your Parked Pages. If you do
          not wish for SiteBuilder to display Parked Pages on your web page you
          can opt out of such practice. Please contact SiteBuilder by phone or
          chat for assistance with opting out.{' '}
        </Typography>
        <ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>
                Standard and Private-Label Reseller Programs.
              </ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                In addition to all terms and conditions described in this
                Agreement, the following shall also be applicable to a User
                participating in the SiteBuilder Reseller Program (collectively,
                the “Reseller”):
              </Typography>
            </li>
            <ul>
              <li>
                <Typography variant='body1' gutterBottom>
                  Reseller shall ensure that each User signed up by Reseller
                  complies with the terms and conditions of this Agreement.
                </Typography>
              </li>
              <li>
                <Typography variant='body1' gutterBottom>
                  Reseller cannot make any modifications to this Agreement. Any
                  such alterations shall be deemed a violation of this Agreement
                  and could result in the cancellation of Reseller’s accounts.
                  SiteBuilder is not responsible for any modifications made to
                  this Agreement by Reseller.
                </Typography>
              </li>
              <li>
                <Typography variant='body1' gutterBottom>
                  In the event that a User signed up by a Reseller is determined
                  to be in violation of this Agreement, Reseller shall, upon
                  receipt of notice of the violation, take prompt action to
                  ensure that the account in question is updated to be in full
                  compliance with this Agreement. In addition, SiteBuilder, in
                  its sole discretion, reserves the right to take action
                  directly if Reseller fails to do so.{' '}
                </Typography>
              </li>
              <li>
                <Typography variant='body1' gutterBottom>
                  SiteBuilder is not responsible for the acts or omissions of
                  Resellers. Reseller hereby agrees to indemnify SiteBuilder
                  from and against any and all claims made by any User or third
                  party arising from the Reseller's acts or omissions.
                </Typography>
              </li>
              <li>
                <Typography variant='body1' gutterBottom>
                  SiteBuilder reserves the right to revise its Reseller Program
                  at any time. Changes shall take effect when posted online or
                  on any subsequent date as may be set forth in any required
                  notice provided by us in connection therewith.
                </Typography>
              </li>
              <li>
                <Typography variant='body1' gutterBottom>
                  Resellers in the Wholesale Reseller Program assume all
                  responsibility for billing and technical support for each of
                  the Users signed up by the Reseller.
                </Typography>
              </li>
              <li>
                <Typography variant='body1' gutterBottom>
                  The Reseller shall maintain accurate, current and complete
                  contact information on each account and promptly update the
                  contact information and any other information you provide to
                  SiteBuilder, to keep such information accurate, current and
                  complete.
                </Typography>
              </li>
            </ul>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Technical Support Services.</ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
              Except as provided in the paragraphs below, SiteBuilder will provide standard technical support 
              for the Services (collectively, the "Technical Support Services") by chat and email 
              to all customers. To utilize the Technical Support Services, please provide as much information 
              as possible to aid our investigation into any issues or problems. SiteBuilder may not be 
              able to provide you with Technical Support Services if you do not provide 
              sufficient information about your issue. By utilizing SiteBuilder's Technical Support Services, 
              you grant SiteBuilder permission to access your account, if necessary, to resolve your issue. 
              You agree that SiteBuilder and its agents and employees are not liable for any damage resulting 
              from the provision of Technical Support Services.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                Limitations of Technical Support Services. SiteBuilder’s
                technical support staff is available to assist you with basic
                technical questions and do not include design recommendations or
                building a website for you. Website design services may be
                purchased separately. SiteBuilder’s Knowledge Base also provides
                answers to common questions regarding our products and services.
                SiteBuilder reserves the right to limit or discontinue your
                access to the Technical Support Services if your requests are
                outside the standard scope of support or you fail to comply with
                the terms of this section, as determined by SiteBuilder in its
                sole discretion.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                Ineligibility for Technical Support Services. Notwithstanding
                the foregoing, SiteBuilder will not provide Technical Support
                Services if (a) you are in breach of this Agreement; (b) the
                issue results from a modification or attempted modification of
                the Services by you or any third party outside of SiteBuilder's
                control; (c) you fail or refuse to follow instructions provided
                by SiteBuilder’s technical support staff to remedy the issue; or
                (d) you are abusive toward SiteBuilder’s staff in any manner.
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Limited Warranty.</ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                SiteBuilder represents and warrants to you that the Services
                will be provided in compliance in all material respects with the
                applicable Services descriptions available on the SiteBuilder
                website. Your sole and exclusive remedy, and SiteBuilder's sole
                obligation, for breach of the foregoing warranty shall be for
                SiteBuilder, at its option, to re-perform the defective Services
                at no cost to you. The foregoing warranties shall not apply to
                performance issues or defects in the Services (x) caused by
                factors outside of SiteBuilder's reasonable control; (y) that
                resulted from any actions or inactions of User or any third
                parties, whether or not authorized by User; or (z) that resulted
                from your equipment or any third-party equipment not within the
                sole control of SiteBuilder.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                THE SERVICES PROVIDED UNDER THIS AGREEMENT ARE PROVIDED ON AN
                “AS IS” AND “AS AVAILABLE BASIS.” EXCEPT AS EXPRESSLY PROVIDED
                IN THIS SECTION, SITEBUILDER AND ITS AFFILIATES, EMPLOYEES,
                AGENTS, SUPPLIERS AND LICENSORS (COLLECTIVELY, THE “SITEBUILDER
                PARTIES”) DISCLAIM ALL WARRANTIES OF ANY KIND, INCLUDING BUT NOT
                LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR
                A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, FOR THE SERVICES
                PROVIDED HEREUNDER. THE SITEBUILDER PARTIES MAKE NO
                REPRESENTATIONS OR WARRANTIES (I) THAT THE SERVICES WILL BE
                UNINTERRUPTED, ERROR FREE, FREE OF VIRUSES, MALWARE OR OTHER
                HARMFUL CODE, OR COMPLETELY SECURE; (II) AS TO THE RESULTS THAT
                MAY BE OBTAINED FROM THE USE OF THE SERVICES; OR (III) AS TO THE
                ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION PROVIDED
                THROUGH THE SERVICES. THE SITEBUILDER PARTIES ARE NOT LIABLE,
                AND EXPRESSLY DISCLAIMS ANY LIABILITY, FOR THE CONTENT OF ANY
                DATA TRANSFERRED EITHER TO OR FROM USERS OR STORED BY USERS ON
                OR THROUGH THE SERVICES. NO ADVICE OR INFORMATION GIVEN BY
                SITEBUILDER OR SITEBUILDER's REPRESENTATIVES INCLUDING, WITHOUT
                LIMITATION, SUPPORT REPRESENTATIVES, SHALL CREATE A WARRANTY.
                SITEBUILDER DOES NOT GUARANTEE THAT USERS WILL BE ABLE TO USE
                THE SERVICES AT TIMES OR LOCATIONS OF THEIR CHOOSING.
                SITEBUILDER DOES NOT WARRANT THAT THE SERVICES ARE COMPATIBLE
                WITH ANY THIRD PARTY SERVICE OR SOFTWARE, EVEN IF SUCH THIRD
                PARTY CLAIMS, REPRESENTS OR WARRANTS THAT SUCH SERVICE OR
                SOFTWARE IS COMPATIBLE WITH ANY SERVICE OR SITEBUILDER IN
                PARTICULAR. THE TERMS OF THIS SECTION SHALL SURVIVE ANY
                TERMINATION OF THIS AGREEMENT.
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Limitation of Liability.</ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                SITEBUILDER SHALL NOT BE LIABLE FOR NONPERFORMANCE OR DELAY IN
                PERFORMANCE CAUSED BY ANY REASON, WHETHER WITHIN OR OUTSIDE OF
                ITS CONTROL. IN NO EVENT WILL THE SITEBUILDER PARTIES BE LIABLE
                TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL,
                EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING
                ANY THAT MAY RESULT FROM UNAUTHORIZED ACCESS TO OR MISUSE OF OUR
                SERVERS AND/OR ANY AND ALL CONTENT, PERSONAL INFORMATION,
                FINANCIAL INFORMATION, SENSITIVE INFORMATION OR OTHER
                INFORMATION OR DATA STORED THEREIN, OR INCLUDING FOR ANY LOST
                PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE SERVICES,
                INCLUDING FROM INTERRUPTION OF SERVICES,, OR ANY USER CONTENT,
                USER WEBSITES OR OTHER MATERIALS ACCESSED OR DOWNLOADED THROUGH
                THE SERVICES, WHETHER OR NOT AUTHORIZED BY YOU, EVEN IF
                SITEBUILDER IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
                SiteBuilder'S LIABILITY TO YOU OR ANY PARTY CLAIMING THROUGH YOU
                FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE
                ACTION, IS LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO
                SiteBuilder FOR THE SERVICES IN THE THREE (3) MONTHS PRIOR TO
                THE INITIAL ACTION GIVING RISE TO LIABILITY. THIS IS AN
                AGGREGATE LIMIT. THE EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER
                WILL NOT INCREASE THIS LIMIT.
              </Typography>
            </li>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Indemnification.</ToSContainer.bold>
            </Typography>
          </li>
        </ul>
        <Typography variant='body1' gutterBottom>
          You agree to indemnify, defend and hold harmless the SiteBuilder
          Parties from and against any and all claims, damages, losses,
          liabilities, suits, actions, demands, proceedings (whether legal or
          administrative), and expenses (including, but not limited to,
          reasonable attorney's fees) threatened, asserted, or filed by a third
          party against any of the SiteBuilder Parties arising out of or
          relating to (i) your use of the Services, (ii) any breach or violation
          by you of this Agreement; or (iii) any of your acts or omissions. The
          terms of this section shall survive any termination of this Agreement.
        </Typography>
        <ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.anchor id='GOVERNING' />
              <ToSContainer.bold>
                Governing Law and Legal Action
              </ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>
                  Governing Law; Jurisdiction.
                </ToSContainer.bold>{' '}
                Any controversy or claim arising out of or relating to this
                Agreement, the formation of this Agreement or the breach of this
                Agreement, including any claim based upon or arising from an
                alleged tort, shall be governed by the substantive laws of the
                Commonwealth of Massachusetts. The United Nations Convention on
                Contracts for the International Sale of Goods does not apply to
                this Agreement. Any suit, action or proceeding concerning this
                Agreement must be brought in a state or federal court located in
                Boston, Massachusetts. Each of the parties hereby irrevocably
                consents to the exclusive jurisdiction of such courts (and of
                the appropriate appellate courts therefrom) in any such suit,
                action or proceeding and irrevocably waives, to the fullest
                extent permitted by applicable law, any objection which it may
                now or hereafter have to the laying of the venue of any such
                suit, action or proceeding in any such court or that any such
                suit, action or proceeding which is brought in any such court
                has been brought in an inconvenient forum. Both you and
                SiteBuilder hereby agree to waive all respective rights to a
                jury trial of any claim arising out of or relating to this
                Agreement.
              </Typography>
              <Typography variant='body1' gutterBottom>
                For all Users who signed up for or purchased Services on or
                after July 1, 2017, the following terms shall apply:
              </Typography>
            </li>
            <li>
              <ToSContainer.anchor id='ARBITRATION' />
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Arbitration.</ToSContainer.bold>
              </Typography>
              <ToSContainer.arbitration>
                For all Users who signed up for or purchased Services on or
                after July 1, 2017, the{' '}
                <ToSContainer.link href='/terms/arbitration-agreement'>
                  Arbitration Agreement
                </ToSContainer.link>{' '}
                shall apply. The Arbitration Agreement includes an agreement
                that all claims will be brought only in an individual capacity
                (and not as a class action or other representative proceeding).
                Please read it carefully. You may opt out of the arbitration
                agreement by following the opt out procedure described therein.
              </ToSContainer.arbitration>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>
                  Governing Law and Jurisdiction for users in the European Union
                </ToSContainer.bold>
              </Typography>
            </li>
            <ul>
              <li>
                <Typography variant='body1' gutterBottom>
                  For users in the European Union, this Agreement, and any
                  non-contractual obligations arising out of, or in relation to
                  it, shall be governed by and construed in accordance with the
                  laws of England and Wales subject only to any mandatory
                  provisions of consumer law in the country in which you reside.
                  The United Nations Convention on Contracts for the
                  International Sale of Goods as well as any other similar law,
                  regulation or statute in effect in any other jurisdiction
                  shall not apply.
                </Typography>
              </li>
              <li>
                <Typography variant='body1' gutterBottom>
                  You and SiteBuilder irrevocably agree that the courts of the
                  country in which you reside shall have non-exclusive
                  jurisdiction to settle any dispute or claim (including
                  non-contractual disputes or claims) arising out of or in
                  connection with this Agreement or its subject matter or
                  formation.
                </Typography>
              </li>
              <li>
                <Typography variant='body1' gutterBottom>
                  Alternatively, you may raise the dispute with an alternative
                  dispute resolution body via the{' '}
                  <ToSContainer.link href='https://webgate.ec.europa.eu/odr/'>
                    EU Commission’s Online Dispute Resolution (ODR) Platform
                  </ToSContainer.link>
                  .
                </Typography>
              </li>
            </ul>
          </ul>
          <br />
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>YouTube API Services.</ToSContainer.bold>
            </Typography>
          </li>
          <Typography variant='body1' gutterBottom>
            Sitebuilder uses Google’s YouTube API Services.
            By using YouTube’s API Services, you agree to
            YouTube’s <ToSContainer.link href='https://www.youtube.com/t/terms'>Terms of Service</ToSContainer.link> and <ToSContainer.link href='https://policies.google.com/privacy'>Privacy Policy</ToSContainer.link>. 
          </Typography>
          <li>
            <Typography variant='h3' gutterBottom>
              <ToSContainer.bold>Miscellaneous.</ToSContainer.bold>
            </Typography>
          </li>
          <ul>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Independent Contractor.</ToSContainer.bold>{' '}
                SiteBuilder and User are independent contractors and nothing
                contained in this Agreement places SiteBuilder and User in the
                relationship of principal and agent, partners or joint
                venturers. Neither party has, expressly or by implication, or
                may represent itself as having, any authority to make contracts
                or enter into any agreements in the name of the other party, or
                to obligate or bind the other party in any manner whatsoever.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Headings.</ToSContainer.bold> The headings
                herein are for convenience only and are not part of this
                Agreement.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Entire Agreement.</ToSContainer.bold> This
                Agreement, including documents incorporated herein by reference,
                supersedes all prior discussions, negotiations and agreements
                between the parties with respect to the subject matter hereof,
                and this Agreement constitutes the sole and entire agreement
                between the parties with respect to the matters covered hereby.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Severability.</ToSContainer.bold> If any
                provision or portion of any provision of this Agreement shall be
                held to be illegal, invalid or unenforceable by a court of
                competent jurisdiction, it is the intention of the parties that
                the remaining provisions or portions (unless otherwise
                specified) thereof shall constitute their agreement with respect
                to the subject matter hereof, and all such remaining provisions
                or portions (unless otherwise specified) thereof shall remain in
                full force and effect.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Waiver.</ToSContainer.bold> No failure or
                delay by any party hereto to exercise any right or remedy
                hereunder shall operate as a waiver thereof, nor shall any
                single or partial exercise of any right or remedy by any party
                preclude any other or further exercise thereof or the exercise
                of any other right or remedy. No express waiver or assent by any
                party hereto to any breach of or default in any term or
                condition of this Agreement shall constitute a waiver of or an
                assent to any succeeding breach of or default in the same or any
                other term or condition hereof.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Assignment; Successors.</ToSContainer.bold>
                You may not assign or transfer this Agreement or any of its
                rights or obligations hereunder, without the prior written
                consent of SiteBuilder. Any attempted assignment in violation of
                the foregoing provision shall be null and void and of no force
                or effect whatsoever. SiteBuilder may assign its rights and
                obligations under this Agreement, and may engage subcontractors
                or agents in performing its duties and exercising its rights
                hereunder, without your consent. This Agreement shall be binding
                upon and shall inure to the benefit of the parties hereto and
                their respective successors and permitted assigns.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Force Majeure.</ToSContainer.bold> Neither
                party is liable for any default or delay in the performance of
                any of its obligations under this Agreement (other than failure
                to make payments when due) if such default or delay is caused,
                directly or indirectly, by forces beyond such party's reasonable
                control, including, without limitation, fire, flood, acts of
                God, labor disputes, accidents, acts of war or terrorism,
                interruptions of transportation or communications, supply
                shortages or the failure of any third party to perform any
                commitment relative to the production or delivery of any
                equipment or material required for such party to perform its
                obligations hereunder.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>
                  Third-Party Beneficiaries.
                </ToSContainer.bold>{' '}
                Except as otherwise expressly provided in this Agreement,
                nothing in this Agreement is intended, nor shall anything herein
                be construed to confer any rights in any person other than the
                parties hereto and their respective successors and permitted
                assigns. Notwithstanding the foregoing, you acknowledge and
                agree that any supplier of a third-party product or service that
                is identified as a third-party beneficiary in the Service
                description, is an intended third-party beneficiary of the
                provisions set forth in this Agreement as they relate
                specifically to its products or services and shall have the
                right to enforce directly the terms and conditions of this
                Agreement with respect to its products or services against you
                as if it were a party to this Agreement.
              </Typography>
            </li>
            <li>
              <Typography variant='body1' gutterBottom>
                <ToSContainer.bold>Government Regulations.</ToSContainer.bold>{' '}
                You may not export, re-export, transfer or make available,
                whether directly or indirectly, any regulated item or
                information to anyone outside the United States (or, if you are
                outside of the United States, to anyone outside of your national
                jurisdiction) in connection with this Agreement without first
                complying with all export control laws and regulations which may
                be imposed by the United States government and any country or
                organization of nations within whose jurisdiction you operate or
                do business.
              </Typography>
            </li>
          </ul>
        </ul>
        <Typography variant='body1' gutterBottom>
          Last updated: October 07, 2024
        </Typography>
      </ToSContainer.content>
    </ToSContainer>
  )
}

export default TermsOfServiceComponent
